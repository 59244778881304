import { useEffect, useMemo, useState } from "react";

import { PlaybackLoader } from "./PlaybackLoader";
import { PlaybackDispatcher } from "./PlaybackDispatcher";
import { ErrorView } from "./ErrorView";

import { useUserAgent } from "hooks/useUserAgent";

import { getPlaybackData } from "services/playback/data";


export const AppWrapper = ({ locale }) => {
  const { isIpad, isTablet } = useUserAgent();

  // TODO: logic to validate session
  const [recordingIsReady, setRecordingIsReady] = useState(false);
  const [dataNotFoundError, setDataNotFoundError] = useState(false);
  const [captureData, setCaptureData] = useState({});

  const meetingId = useMemo(
    // TODO: WIP
    () => window.location.pathname.substring(1) || "TEST_MEETING",
    []
  );

  const preRenderedToken = useMemo(() => {
    let serverToken;

    const url = new URL(window.location.href);
    const urlHash = new URLSearchParams(url.hash.substring(1))

    if (urlHash.get('token')) {
      serverToken = urlHash.get('token');
    }

    if (serverToken) {
      window.sessionStorage.setItem("token", serverToken);

      window.history.pushState({}, document.title, window.location.pathname);
      return serverToken;
    }

    const sessionStorageToken = window.sessionStorage.getItem("token");
    if (!serverToken && sessionStorageToken) return sessionStorageToken;

    return undefined;
  }, []);

  const preRenderedError = useMemo(() => {
    let error = null;

    const url = new URL(window.location.href);
    const urlHash = new URLSearchParams(url.hash.substring(1))

    if (!urlHash.get('errorType')) return null;
    const errorType = urlHash.get('errorType')
    error = { errorType, errorCode: null }

    return error;
  }, []);

  const getData = async (token) => {
    if (!token) return;

    const data = await getPlaybackData(token);

    if (!data) {
      setDataNotFoundError(true);
      return;
    }

    setCaptureData(data);

    setRecordingIsReady(true);
    // delete token from hash parameter
    const url = new URL(window.location.href);
    window.history.replaceState({}, "", `${url.protocol}//${url.host}${url.pathname}`);
  };

  useEffect(() => {
    if (!preRenderedToken) return;

    getData(preRenderedToken);
  }, [preRenderedToken]);

  if (isTablet && isIpad) {
    return <ErrorView type={ErrorView.types.IPAD_NOT_SUPPORTED} />;
  }

  if (!!preRenderedError || dataNotFoundError || !preRenderedToken) {
    if (!!preRenderedError) {
      const { errorType, errorCode } = preRenderedError;
      if (Object.keys(ErrorView.types).includes(errorType)) {
        return <ErrorView type={ErrorView.types[errorType]} code={errorCode} />;
      }
    }

    return <ErrorView type={ErrorView.types.ERROR_404} />;
  }

  return (
    <>
      {recordingIsReady ? (
        <PlaybackDispatcher meetingId={meetingId} data={captureData} />
      ) : (
        <PlaybackLoader />
      )}
    </>
  );
};
