import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Duration } from "luxon";
import { useIntl } from "react-intl";

import { AppIcon } from "components/elements/AppIcon";
import { Slide } from "../../Slide";

import { HIDE_SCROLL } from "theme/ui";

export const Slides = () => {
  const intl = useIntl();
  const thumbnails = useSelector((state) => state.slides.thumbnails);

  return thumbnails.length === 0 ? (
    <ScrollContainer className="w-full h-full flex flex-col justify-center items-center gap-6 p-12">
      <AppIcon type={AppIcon.types.EMPTY_SLIDES} />
      <h3 className="font-nunito font-normal text-14 text-center text-black">
        {intl.formatMessage({ id: "panel.slides.empty.title" })}
      </h3>
    </ScrollContainer>
  ) : (
    <SlidesGridContent className="w-full h-full flex flex-col items-center justify-start overflow-auto py-4 px-8">
      {thumbnails.map((thumbnail) => {
        const duration = Duration.fromObject({ seconds: thumbnail.time });
        const time = duration.toFormat("mm:ss");

        return (
          <div key={thumbnail.id} className="flex flex-row flex-no-wrap items-center gap-x-8">
            <div className="relative h-full flex flex-col justify-center items-center">
              <span className="h-full bg-black12" style={{ width: 2 }} />
              <div className="py-4 bg-white">
                <div className="bg-black flex items-center justify-center p-2 rounded-xxl">
                  <p className="text-12 font-normal px-2 text-white">{time}</p>
                </div>
              </div>
              <span className="h-full bg-black12" style={{ width: 2 }} />
            </div>
            <div className="flex py-3">
              <Slide thumbnail={thumbnail} />
            </div>
          </div>
        );
      })}
    </SlidesGridContent>
  );
};

const ScrollContainer = styled.div`
  ${HIDE_SCROLL}
`;

const SlidesGridContent = styled.div`
  ${HIDE_SCROLL}
`;
