import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import styled from "styled-components";

import { eventTimer } from "services/timer";

import { slidesActions } from "store/slides";

import { useThumbnailSelected } from "hooks/useThumbnailSelected";

import { SLIDE_HEIGHT, SLIDE_WIDTH } from "theme/ui";

export const Slide = ({ thumbnail }) => {
  const dispatch = useDispatch();
  const { isThumbnailSelected } = useThumbnailSelected({ thumbnail });

  const onChangeSlide = (position, time) => {
    eventTimer.requestSeek(time);

    dispatch(slidesActions.slideIndexChanged(position));
    dispatch(slidesActions.slidesVisibleClosed());
  };

  return (
    <SlideWrapper
      className={classNames(
        "relative overflow-hidden cursor-pointer rounded-xxxxl",
        isThumbnailSelected ? "border-4 border-turquoise p-2" : ""
      )}
      selected={isThumbnailSelected}
      onClick={() => onChangeSlide(thumbnail.index, thumbnail.time)}
    >
      <div className="w-full h-full overflow-hidden rounded-xxl">
        <img src={thumbnail.source} alt="" className="w-full h-full object-cover object-center" />
      </div>
    </SlideWrapper>
  );
};

const SlideWrapper = styled.div`
  max-width: ${SLIDE_WIDTH}px;
  max-height: ${SLIDE_HEIGHT}px;
  box-shadow: 0px 2px 4px -1px #33374540;
`;
