export const boardsContent = {
  BLANK: {
    width: 1440,
    height: 810,
    source: "/boards/blank/slide.jpg",
    webp: "/boards/blank/slide.webp",
    thumb: "/boards/blank/thumb.jpg",
  },
  DOT_GRID: {
    width: 1440,
    height: 810,
    source: "/boards/dot-grid/slide.jpg",
    webp: "/boards/dot-grid/slide.webp",
    thumb: "/boards/dot-grid/thumb.jpg",
  },
  LINES: {
    width: 1440,
    height: 810,
    source: "/boards/lines/slide.jpg",
    webp: "/boards/lines/slide.webp",
    thumb: "/boards/lines/thumb.jpg",
  },
  SQUARE_GRID: {
    width: 1440,
    height: 810,
    source: "/boards/square-grid/slide.jpg",
    webp: "/boards/square-grid/slide.webp",
    thumb: "/boards/square-grid/thumb.jpg",
  },
};
