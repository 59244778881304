import React from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import { Message } from "./Message";

import {
  CHAT_FULLSCREEN_WIDTH,
  PRESENTATION_LG_MARGIN,
  CHAT_FULLSCREEN_XS_MARGIN_BOTTOM,
  CHAT_FULLSCREEN_MD_MARGIN_BOTTOM,
  CHAT_FULLSCREEN_LG_MARGIN_BOTTOM,
  BREAKPOINTS,
  TABLET_MARGIN,
  MOBILE_MARGIN,
} from "theme/ui";

const TRANSLATE_X = CHAT_FULLSCREEN_WIDTH + TABLET_MARGIN;

export const ChatMessages = () => {
  const chatActivated = useSelector((state) => state.player.chatActivated);
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);

  const messages = useSelector((state) => state.messages.messages);

  return (
    <ChatMessageWrapper
      chatEnabled={chatActivated}
      className="flex flex-col justify-end items-start gap-4 absolute left-0 bottom-0 md:mb-6 md:ml-6 "
      fullScreenVisible={fullScreenVisible}
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <Message key={message.id} message={message.chatMessageEvent.message} />
        ))}
    </ChatMessageWrapper>
  );
};

const ChatMessageWrapper = styled.div`
  width: ${CHAT_FULLSCREEN_WIDTH}px;
  transform: translateX(-${TRANSLATE_X}px);

  ${(props) =>
    props.fullScreenVisible &&
    css`
      margin-bottom: ${CHAT_FULLSCREEN_LG_MARGIN_BOTTOM}px !important;
      margin-left: ${PRESENTATION_LG_MARGIN}px !important;

      @media (max-width: ${BREAKPOINTS.md}px) {
        margin-bottom: ${CHAT_FULLSCREEN_MD_MARGIN_BOTTOM}px !important;
        margin-left: ${TABLET_MARGIN}px !important;
      }

      @media (max-width: ${BREAKPOINTS.xs}px) {
        margin-bottom: ${CHAT_FULLSCREEN_XS_MARGIN_BOTTOM}px !important;
        margin-left: ${MOBILE_MARGIN}px !important;
      }
    `};

  ${(props) =>
    props.chatEnabled
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `};

  transition: opacity 0.3s, visibility 0.3s;
`;
