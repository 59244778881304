import { useEffect, useState } from "react";

import { eventTimer } from "services/timer";

export const useThumbnailSelected = ({ thumbnail }) => {
  const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);

  useEffect(() => {
    const subscription = eventTimer.selectedThumbnail$.subscribe((selectedThumb) => {
      if (thumbnail.id === selectedThumb.id) setIsThumbnailSelected(true);
      else setIsThumbnailSelected(false);
    });

    return () => subscription.unsubscribe();
  }, [thumbnail]);

  return { isThumbnailSelected };
};
