import React from "react";
import { useSelector } from "react-redux";

import { BoardBackground } from "./BoardBackground";
import { SlideBackground } from "./SlideBackground";

import { DEFAULT_TRANSITION_SVG } from "ui";

export const WhiteboardBackground = ({ imageBox }) => {
  const fileId = useSelector((state) => state.slides.fileId);
  const boardId = useSelector((state) => state.slides.boardId);

  if (!fileId && !boardId) return null;

  return (
    <g>
      <foreignObject
        x={imageBox.x}
        y={imageBox.y}
        width={imageBox.width}
        height={imageBox.height}
        style={{ transition: DEFAULT_TRANSITION_SVG }}
      >
        {fileId ? <SlideBackground fileId={fileId} /> : null}
        {boardId ? <BoardBackground boardId={boardId} /> : null}
      </foreignObject>
    </g>
  );
};
