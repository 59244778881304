import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { AppIcon } from "components/elements/AppIcon";
import { File } from "./File";
import { Video } from "./Video";

import { HIDE_SCROLL } from "theme/ui";

export const Files = () => {
  const intl = useIntl();

  const filesMap = useSelector((state) => state.files);
  const videosMap = useSelector((state) => state.videos);

  const files = Object.values(filesMap);
  const videos = Object.values(videosMap);

  return files.length === 0 && videos.length === 0 ? (
    <ScrollContainer className="w-full h-full flex flex-col justify-center items-center gap-6 p-12">
      <AppIcon type={AppIcon.types.EMPTY_LIBRARY} />
      <h3 className="font-nunito font-normal text-14 text-center text-black">
        {intl.formatMessage({ id: "panel.files.empty.label" })}
      </h3>
    </ScrollContainer>
  ) : (
    <FilesContent className="h-full w-full flex flex-col gap-6 overflow-y-auto">
      {files.map((file) => (
        <File key={file.sid} file={file} />
      ))}
      {videos.map((video) => (
        <Video key={video.id} video={video} />
      ))}
    </FilesContent>
  );
};

const ScrollContainer = styled.div`
  ${HIDE_SCROLL}
`;

const FilesContent = styled.div`
  ${HIDE_SCROLL}
`;
