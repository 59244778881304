import findLast from "lodash.findlast";

/**
 *
 * @param {import("./data").PlaybackData} data
 * @param {Number} time
 */
export const getVideoSharingAtTime = (data, time) => {
  const videoSharingAtTime = findLast(data.videoSharing.events, (s) => time >= s.time);

  return videoSharingAtTime || null;
};

/**
 *
 * @param {PlaybackData} data
 */
export const getVideos = (videoSharings) => {
  const set = new Set();
  const videos = [];
  videoSharings.forEach((video) => {
    const { videoId, data } = video;
    if (!set.has(videoId)) {
      videos.push(data);
    }

    set.add(video.videoId);
  });

  return videos;
};
