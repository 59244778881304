import { createSlice } from "@reduxjs/toolkit";

export const slidesSlice = createSlice({
  name: "slides",
  initialState: {
    slides: {},
    thumbnails: [],
    slideIndex: null,
    fileId: null,
    boardId: null,
    slideId: null,
    slidesVisible: false,
    zoom: 1,
    x: 0,
    y: 0,
  },
  reducers: {
    initialized: (state, action) => {
      const { thumbnails, slides } = action.payload;
      state.thumbnails = thumbnails;
      state.slides = slides;
    },
    slidesReceived: (state, action) => {
      state.slides = action.payload;
    },
    slidesVisibleToggled: (state) => {
      state.slidesVisible = !state.slidesVisible;
    },
    slidesVisibleClosed: (state) => {
      state.slidesVisible = false;
    },
    slideIndexChanged: (state, action) => {
      state.slideIndex = action.payload;
    },
    fileIdChanged: (state, action) => {
      state.fileId = action.payload;
    },
    boardIdChanged: (state, action) => {
      state.boardId = action.payload;
    },
    slideIdChanged: (state, action) => {
      state.slideId = action.payload;
    },
    zoomChanged: (state, action) => {
      state.zoom = action.payload;
    },
    coordinatesChanged: (state, action) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
    },
  },
});

export const slidesActions = slidesSlice.actions;
export const slidesReducer = slidesSlice.reducer;
