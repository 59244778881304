import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Playback } from "components/Playback";

import { eventTimer } from "services/timer";

import { MEDIA_EVENT_STATUS, PLAYBACK_MEDIA_TYPES } from "lib/player";
import { DRAWING_TYPE } from "lib/drawings";

import { drawingsActions } from "store/drawings";
import { navigationActions } from "store/navigation";
import { usersActions } from "store/users";
import { slidesActions } from "store/slides";
import { messagesActions } from "store/mesages";

export const PlaybackListener = () => {
  const dispatch = useDispatch();

  const currentSlideIndex = useSelector((state) => state.slides.slideIndex);
  const currentSlideId = useSelector((state) => state.slides.slideId);
  const currentFileId = useSelector((state) => state.slides.fileId);
  const currentBoardId = useSelector((state) => state.slides.boardId);

  useEffect(() => {
    const subscription = eventTimer.slides$.subscribe((event) => {
      let slide = null;

      if (event.slideEvent.slidePresented) {
        slide = event.slideEvent.slidePresented.slide;
      } else if (event.slideEvent.slideUpdated) {
        slide = event.slideEvent.slideUpdated.slide;
      }

      if (!slide) return;

      if (slide.index !== currentSlideIndex) {
        dispatch(slidesActions.slideIndexChanged(slide.index));
      }

      if (slide.fileId !== currentFileId) {
        dispatch(slidesActions.fileIdChanged(slide.fileId));
      }

      if (slide.boardId !== currentBoardId) {
        dispatch(slidesActions.boardIdChanged(slide.boardId));
      }

      dispatch(slidesActions.slideIdChanged(slide.sid));
      dispatch(slidesActions.zoomChanged(slide.zoom));
      dispatch(slidesActions.coordinatesChanged({ x: slide.x, y: slide.y }));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.drawings$.subscribe((events) => {
      if (events === null || currentSlideId == null) return;

      let drawings = [];
      let freePenDrawings = [];

      events.forEach((event) => {
        if (!event.drawingEvent) return;

        if (event.drawingEvent.drawingCreated) {
          const { slideId, drawing } = event.drawingEvent.drawingCreated;
          if (!drawing) return;
          if (slideId !== currentSlideId) return;

          const { typeDrawing } = drawing;

          if (typeDrawing === DRAWING_TYPE.PEN) {
            freePenDrawings.push(drawing);
          } else {
            drawings.push(drawing);
          }
        } else if (event.drawingEvent.drawingUpdated) {
          const { slideId, drawing: updatedDrawing } = event.drawingEvent.drawingUpdated;
          if (!updatedDrawing) return;
          if (slideId !== currentSlideId) return;

          const drawingIndex = drawings.findIndex((drawing) => drawing.sid === updatedDrawing.sid);
          if (drawingIndex === -1) return;

          drawings[drawingIndex] = updatedDrawing;
        } else if (event.drawingEvent.drawingDeleted) {
          const { slideId, drawingId } = event.drawingEvent.drawingDeleted;
          if (slideId !== currentSlideId) return;

          drawings = drawings.filter((drawing) => drawing.sid !== drawingId);
        } else if (event.drawingEvent.cleanAll) {
          const { slideId } = event.drawingEvent.cleanAll;
          if (slideId !== currentSlideId) return;

          drawings = [];
          freePenDrawings = [];
        }
      });

      dispatch(drawingsActions.drawingsUpdated(drawings));
      dispatch(drawingsActions.freePenUpdated(freePenDrawings));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [currentSlideId]);

  useEffect(() => {
    const subscription = eventTimer.medias$.subscribe(({ medias }) => {
      if (medias[PLAYBACK_MEDIA_TYPES.VIDEO]) {
        dispatch(navigationActions.isPresentingCameraUpdated(true));
      } else {
        dispatch(navigationActions.isPresentingCameraUpdated(false));
      }

      if (medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]) {
        dispatch(navigationActions.screenSharingActivated());
      } else {
        dispatch(navigationActions.screenSharingDesactivated());
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.presenter$.subscribe((event) => {
      if (!event.presenterEvent) return;
      dispatch(usersActions.presenterUpdated(event.presenterEvent));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.videoSharings$.subscribe((event) => {
      if (!event) return dispatch(navigationActions.videoSharingDesactivated());

      if (event.videoSharingEvent?.videoStopped)
        return dispatch(navigationActions.videoSharingDesactivated());

      dispatch(navigationActions.videoSharingActivated());
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.messages$.subscribe((messagesFromSubscription) => {
      dispatch(messagesActions.addNewMessages(messagesFromSubscription));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = eventTimer.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.VIDEO].subscribe(
      (cameraStatus) => {
        if (cameraStatus === MEDIA_EVENT_STATUS.CAN_PLAY) {
          dispatch(navigationActions.isLoadingCameraUpdated(false));
        } else {
          dispatch(navigationActions.isLoadingCameraUpdated(true));
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = eventTimer.playbackMediasStatuses$[
      PLAYBACK_MEDIA_TYPES.SCREEN_SHARING
    ].subscribe((screenShareStatus) => {
      if (screenShareStatus === MEDIA_EVENT_STATUS.CAN_PLAY) {
        dispatch(navigationActions.isLoadingScreenShareUpdated(false));
      } else {
        dispatch(navigationActions.isLoadingScreenShareUpdated(true));
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return <Playback />;
};
