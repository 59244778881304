import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { Slide } from "./Slide";

import { usePresentationAreaSize } from "hooks/usePresentationAreaSize";
import useMediaWidthFitted from "hooks/useMediaWidthFitted";

import {
  BREAKPOINTS,
  HIDE_SCROLL,
  PREVIEW_SLIDES_GAP,
  PREVIEW_SLIDES_LG_HEIGHT,
  PREVIEW_SLIDES_LG_WIDTH,
  PREVIEW_SLIDES_MD_HEIGHT,
  PREVIEW_SLIDES_MD_WIDTH,
  PREVIEW_SLIDES_XS_HEIGHT,
  PREVIEW_SLIDES_XS_WIDTH,
  SLIDE_MD_MIN_WIDTH,
  SLIDE_LANDSCAPE_XS_WIDTH,
  SLIDE_PORTRAIT_MD_HEIGHT,
  PREVIEW_SLIDES_LG_MARGIN_BOTTOM,
} from "theme/ui";

export const SlidesPresentation = () => {
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const miniPresentationCollapsed = useSelector(
    (state) => state.navigation.miniPresentationCollapsed
  );

  const thumbnails = useSelector((state) => state.slides.thumbnails);
  const slidesVisible = useSelector((state) => state.slides.slidesVisible);

  const [widthFirstSlide, setWidthFirstSlide] = useState(0);

  const firstSlideRef = useRef(null);

  const presentationAreaSize = usePresentationAreaSize();
  const { isMediaReponsive } = useMediaWidthFitted();

  useEffect(() => {
    if (thumbnails.length > 0) setWidthFirstSlide(firstSlideRef.current.offsetWidth);
  }, [thumbnails.length, isMediaReponsive]);

  return (
    <SlidesWrapper
      className={classNames(
        "absolute md:right-0 md:bottom-0 flex flex-col bg-black rounded-xxl md:mb-6 md:mr-6 p-6",
        { "md:mr-12": fullScreenVisible }
      )}
      slidesVisible={slidesVisible}
      presentationAreaWidth={presentationAreaSize.width}
      presentationAreaHeight={presentationAreaSize.height}
      fullScreenVisible={fullScreenVisible}
      miniPresentationCollapsed={miniPresentationCollapsed}
      slidesLength={thumbnails.length}
    >
      <SlidesGridContent
        className="w-full h-full overflow-y-scroll"
        presentationAreaHeight={presentationAreaSize.height}
        widthFirstSlide={widthFirstSlide}
      >
        {thumbnails.map((thumbnail, index) => (
          <Slide
            key={thumbnail.id}
            ref={index === 0 ? firstSlideRef : undefined}
            thumbnail={thumbnail}
          />
        ))}
      </SlidesGridContent>
    </SlidesWrapper>
  );
};

const SlidesWrapper = styled.div`
  width: ${PREVIEW_SLIDES_LG_WIDTH}px;
  height: ${(props) =>
    PREVIEW_SLIDES_LG_HEIGHT(
      props.presentationAreaHeight,
      props.fullScreenVisible,
      props.miniPresentationCollapsed,
      props.slidesLength
    )}px;

  margin-bottom: ${(props) =>
    props.fullScreenVisible && `${PREVIEW_SLIDES_LG_MARGIN_BOTTOM}px !important`};

  ${(props) =>
    props.slidesVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  transition: opacity 0.3s, visibility 0.3s, height 0.3s;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: ${(props) => PREVIEW_SLIDES_MD_WIDTH(props.presentationAreaWidth)}px;
    height: ${(props) => PREVIEW_SLIDES_MD_HEIGHT(props.presentationAreaHeight)}px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: opacity 0.3s, visibility 0.3s, height 0s;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: ${(props) => PREVIEW_SLIDES_XS_WIDTH(props.presentationAreaWidth)}px;
    height: ${(props) => PREVIEW_SLIDES_XS_HEIGHT(props.presentationAreaHeight)}px;
  }
`;

const SlidesGridContent = styled.div`
  ${HIDE_SCROLL}

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${PREVIEW_SLIDES_GAP}px;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: repeat(auto-fill, minmax(${SLIDE_MD_MIN_WIDTH}px, 1fr));
    grid-auto-rows: ${(props) => SLIDE_PORTRAIT_MD_HEIGHT(props.widthFirstSlide)}px;
  }

  @media (max-width: ${BREAKPOINTS.md}px) and (orientation: landscape) {
    overflow-x: scroll !important;

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      ${(props) => SLIDE_LANDSCAPE_XS_WIDTH(props.presentationAreaHeight)}px
    );
    grid-auto-columns: ${(props) => SLIDE_LANDSCAPE_XS_WIDTH(props.presentationAreaHeight)}px;
    grid-template-rows: auto;
    grid-auto-flow: column;
  }
`;
