import get from "lodash.get";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { boardsContent } from "lib/boards";

import { DEFAULT_TRANSITION_SVG } from "ui";

export const BoardBackground = ({ boardId }) => {
  const board = useSelector((state) => state.boards[boardId]);
  const slideIndex = useSelector((state) => state.slides.slideIndex);

  const boardContent = useMemo(() => {
    const page = get(board, `pages[${slideIndex}]`);
    if (!page) return null;

    return boardsContent[page.type];
  }, [board, slideIndex]);

  if (!boardContent) return null;

  return (
    <picture>
      <source srcSet={boardContent.webp} type="image/webp" />
      <source srcSet={boardContent.source} type="image/jpg" />
      <img
        src={boardContent.source}
        width="100%"
        alt=""
        style={{ transition: DEFAULT_TRANSITION_SVG }}
        id="imagebox-img"
      />
    </picture>
  );
};
