import get from "lodash.get";

import { boardsContent } from "lib/boards";
import findLast from "lodash.findlast";

/**
 * @param {import("./data").PlaybackDataV2} data
 * @param {Number} time
 * @returns {import("./data").SlideEvent | null}
 */
export const getSlideEventsAtTime = (data, time) => {
  const events = get(data.slides, "events", []);

  /** @type {import("./data").SlideEvent} */
  const event = findLast(events, (event) => time >= event.time);

  return event ?? null;
};

/**
 *
 * @param {import("./data").PlaybackData} data
 */
export const getSlideTumbnails = (data) => {
  /**
   * @type {import("./data").SlideEvent[]}
   */
  const events = get(data.slides, "events", []);

  /**
   * @type {import("./data").SlideThumbnail[]}
   */
  const thumbnails = [];

  events.forEach((e) => {
    if (!e.slideEvent) return;
    if (!e.slideEvent.slidePresented) return;

    const { slide } = e.slideEvent.slidePresented;

    if (slide.boardId) {
      const board = data.boards[slide.boardId];
      if (!board) return;

      const page = board.pages[slide.index];
      if (!page) return;

      const content = boardsContent[page.type];
      if (!content) return;

      thumbnails.push({
        time: e.time,
        source: content.thumb,
        id: e.id,
        boardId: slide.boardId,
        index: slide.index,
        slideId: slide.sid,
      });
      return;
    }

    if (slide.fileId) {
      const file = data.files[slide.fileId];
      if (!file) return;

      const current = file.slides[slide.index];
      if (!current) return;

      if (current.fileSlide) {
        const fileSlide = data.slides.slides[current.fileSlide.sid];
        if (!fileSlide) return;

        thumbnails.push({
          time: e.time,
          source: fileSlide.thumb,
          id: e.id,
          fileId: slide.fileId,
          index: slide.index,
          slideId: slide.sid,
        });
        return;
      }

      if (current.boardPage) {
        const content = boardsContent[current.boardPage.type];
        if (!content) return;

        thumbnails.push({
          time: e.time,
          source: content.thumb,
          id: e.id,
          fileId: slide.fileId,
          index: slide.index,
          slideId: slide.sid,
        });
        return;
      }
    }
  });

  return thumbnails;
};

/**
 * @param {import("./data").PlaybackDataV2} data
 * @param {Number} time
 * @returns {import("./data").SlideThumbnail | null}
 */
export const getSelectedThumbnailAtTime = (data, time) => {
  const thumbs = get(data, "thumbnails", []);

  const thumb = findLast(thumbs, (thumb) => time >= thumb.time);

  return thumb ?? null;
};
