import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import classNames from "classnames";

import { MEDIA_ELEMENT_NAMES } from "lib/player";

import { playerActions } from "store/player";

import { colors } from "theme";

export const VolumenRange = () => {
  const dispatch = useDispatch();

  const { volume, muted } = useSelector((state) => state.player);
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);

  const onChange = (e) => {
    const value = parseFloat(e.target.value);

    /** @type {HTMLAudioElement} */
    const videoEl = document.getElementById(MEDIA_ELEMENT_NAMES.VIDEO);
    if (videoEl) {
      dispatch(playerActions.volumeChanged(value));

      videoEl.volume = value;
    }
  };

  return (
    <Styled
      className={classNames(
        "relative rounded-md",
        fullScreenVisible ? "bg-fafafa12" : "bg-black12"
      )}
      muted={muted}
      fullScreenVisible={fullScreenVisible}
    >
      <div
        className={classNames("relative rounded-md", fullScreenVisible ? "bg-fafafa" : "bg-black")}
        style={{ width: `${volume * 100}%`, height: 4 }}
      />
      <input
        type="range"
        className="w-full"
        min={0}
        max={1}
        step={0.05}
        defaultValue={volume}
        onChange={onChange}
      />
    </Styled>
  );
};

const Styled = styled.div`
  width: 64px;
  height: 4px;

  ${(p) =>
    p.muted
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};

  input {
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      opacity: 1;

      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${(p) => (p.fullScreenVisible ? colors.white : colors.black)};
      cursor: pointer;
    }
  }
`;
