import { useEffect, useState } from "react";

import { eventTimer } from "services/timer";

export const useVideoSharing = () => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const subscription = eventTimer.videoSharings$.subscribe((event) => {
      if (!event.videoSharingEvent) return;

      if (event.videoSharingEvent.videoStarted) {
        const { video } = event.videoSharingEvent.videoStarted;
        if (!video) return;

        setVideo(video);
      } else if (event.videoSharingEvent.videoUpdated) {
        const { video } = event.videoSharingEvent.videoUpdated;
        if (!video) return;

        setVideo(video);
      } else if (event.videoSharingEvent.videoStopped) {
        setVideo(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return { video };
};
