import React from "react";
import { useIntl } from "react-intl";
import { Duration } from "luxon";

import { AppIcon } from "components/elements/AppIcon";
import { IconClickeable } from "components/elements/IconClickeable";

import { getIconColorByVideoType } from "lib/files";

export const Video = ({ video }) => {
  const intl = useIntl();

  const duration = Duration.fromObject({ seconds: video.duration }).toFormat(
    video.duration >= 60 * 60 * 1000 ? "hh:mm:ss" : "mm:ss"
  );

  const onDownload = () => window.open(video.url, "_blank");

  return (
    <div className="flex flex-row items-center p-6 gap-4">
      <div className="flex justify-center items-center p-4">
        <AppIcon
          type={AppIcon.types.YOUTUBE}
          size={24}
          style={{ fill: getIconColorByVideoType(video.type) }}
        />
      </div>
      <div className="w-full flex flex-col gap-2 overflow-hidden">
        <div className="text-14 text-black truncate px-2">{video.name}</div>
        <div className="text-12 text-black50 px-2">{duration}</div>
        <div className="text-12 text-black50 px-2">{video.participant.name}</div>
      </div>
      <div className="flex justify-center items-center p-4">
        <IconClickeable
          className="fill-black25"
          size="sm"
          icon={IconClickeable.types.VIDEO_LINK}
          fill="black"
          hoverFill="black"
          label={intl.formatMessage({ id: "panel.files.video.open.label" })}
          onClick={onDownload}
        />
      </div>
    </div>
  );
};
