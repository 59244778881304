import findLast from "lodash.findlast";

/**
 * @param {import("./data").PlaybackData} data
 */
export const getFirstPresenter = (data) => {
  if (data.presenters.length === 0) return null;

  const event = data.presenters[0];
  if (!event) return null;

  return event.presenterEvent;
};

/**
 * @param {import("./data").PlaybackData} data
 * @param {Number} time
 */
export const getPresenterAtTime = (data, time) => {
  const presenter = findLast(data.presenters, (c) => time >= c.time);
  return presenter;
};
