import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash.get";
import classNames from "classnames";
import styled, { css } from "styled-components";

import { AppIcon } from "components/elements/AppIcon";
import { AppLoader } from "components/elements/AppLoader";
import { AppClickeable } from "../elements/AppClickeable";
import { MediaPlayer } from "../MediaPlayer";

import { usePresentationAreaSize } from "hooks/usePresentationAreaSize";
import useMediaWidthFitted from "hooks/useMediaWidthFitted";

import { MEDIA_ELEMENT_NAMES } from "lib/player";

import { eventTimer } from "services/timer";

import { navigationActions } from "store/navigation";

import {
  BREAKPOINTS,
  PRESENTATION_BUTTON_SIZE,
  GRID_GAP,
  MINI_PRESENTATION_HEIGHT,
  MINI_PRESENTATION_WIDTH,
  WINDOW_MARGIN,
} from "theme/ui";

export const MiniPresentation = () => {
  const dispatch = useDispatch();

  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const screenSharingActive = useSelector((state) => state.navigation.screenSharingActive);
  const isPresentingCamera = useSelector((state) => state.navigation.isPresentingCamera);
  const isLoadingCamera = useSelector((state) => state.navigation.isLoadingCamera);
  const currentPresenter = useSelector((state) => state.users.currentPresenter);

  const isMiniPresentationExpanded = useSelector((state) =>
    get(state, "navigation.isMiniPresentationExpanded")
  );
  const miniPresentationCollapsed = useSelector(
    (state) => state.navigation.miniPresentationCollapsed
  );

  const presentationArea = usePresentationAreaSize();
  const { isMediaDesktop } = useMediaWidthFitted();

  useEffect(() => {
    /** @type {HTMLVideoElement} */
    const videoEl = document.getElementById(MEDIA_ELEMENT_NAMES.VIDEO);
    if (videoEl) {
      eventTimer.initPlaybackVideo(videoEl);
    }
  }, []);

  return (
    <MiniPresentationWrapper
      className={classNames(
        "hidden md:flex fixed top-0 overflow-hidden shadow-all  rounded-xxl",
        { "right-0": !isMiniPresentationExpanded },
        { "left-0": isMiniPresentationExpanded },
        { "bg-black": miniPresentationCollapsed },
        { "bg-black25": !miniPresentationCollapsed },
        { "z-10": fullScreenVisible && !isMiniPresentationExpanded && screenSharingActive }
      )}
      fullScreenVisible={fullScreenVisible}
      isMiniPresentationExpanded={isMiniPresentationExpanded}
      presentationAreaWidth={presentationArea.width}
      presentationAreaHeight={presentationArea.height}
      miniPresentationCollapsed={miniPresentationCollapsed}
    >
      <MediaPlayer
        id={MEDIA_ELEMENT_NAMES.VIDEO}
        className={classNames(
          "w-full h-full",
          {
            "opacity-0 invisible": miniPresentationCollapsed || !isPresentingCamera,
          },
          {
            "opacity-100 visible": !miniPresentationCollapsed && isPresentingCamera,
          }
        )}
        mediaType="video/mp4"
      />

      {!isPresentingCamera && !miniPresentationCollapsed ? (
        <div
          className="h-full w-full absolute bg-dark flex justify-center items-center text-center px-10 text-white tracking-40 select-none text-16 overflow-hidden text-ellipsis"
          style={{
            lineClamp: 1,
            boxOrient: "vertical",
          }}
        >
          {!!currentPresenter && currentPresenter.name}
        </div>
      ) : null}

      {isPresentingCamera && isLoadingCamera && !miniPresentationCollapsed ? (
        <div className="h-full w-full absolute flex justify-center items-center bg-dark">
          <AppLoader loaderType="camera" />
        </div>
      ) : null}

      {(!fullScreenVisible || (fullScreenVisible && !isMiniPresentationExpanded)) &&
        isMediaDesktop &&
        !!currentPresenter &&
        isPresentingCamera && (
          <div className="name-user flex justify-center items-center absolute bg-black  truncate text-12 rounded-xxl px-6 py-2 ">
            <UsernameWrapper className="text-white tracking-40 select-none">
              {currentPresenter.name}
            </UsernameWrapper>
          </div>
        )}

      {!isMiniPresentationExpanded && isPresentingCamera && (
        <AppClickeable
          className={classNames(
            "absolute top-0 left-0 flex items-center justify-center bg-black rounded-xxl",
            { "ml-4 mt-4": !miniPresentationCollapsed },
            { "ml-0 mt-0": miniPresentationCollapsed },
            { "ml-6 mt-6": !fullScreenVisible }
          )}
          style={{
            width: PRESENTATION_BUTTON_SIZE,
            height: PRESENTATION_BUTTON_SIZE,
            transition: "margin 0.3s",
          }}
          onClick={() => dispatch(navigationActions.presentationToggled())}
        >
          <AppIcon type={AppIcon.types.EXPAND} className="fill-white" size={24} />
        </AppClickeable>
      )}

      {fullScreenVisible && !isMiniPresentationExpanded && (
        <AppClickeable
          className={classNames(
            "absolute top-0 right-0 flex items-center justify-center bg-black rounded-xxl",
            { "mr-4 mt-4": !miniPresentationCollapsed },
            { "ml-0 mt-0": miniPresentationCollapsed }
          )}
          style={{
            width: PRESENTATION_BUTTON_SIZE,
            height: PRESENTATION_BUTTON_SIZE,
            transition: "margin 0.3s",
          }}
          onClick={() => dispatch(navigationActions.miniPresentationCollapsedToggled())}
        >
          <AppIcon
            type={miniPresentationCollapsed ? AppIcon.types.CHEVRON_DOWN : AppIcon.types.CHEVRON_UP}
            className="fill-white"
            size={24}
          />
        </AppClickeable>
      )}

      {(!fullScreenVisible || (fullScreenVisible && !isMiniPresentationExpanded)) &&
        isMediaDesktop &&
        !!currentPresenter && (
          <div className="name-user-collapsed absolute bg-black text-white truncate text-12 tracking-40 rounded-xxl px-6 py-2 select-none">
            {currentPresenter.name}
          </div>
        )}
    </MiniPresentationWrapper>
  );
};

const MiniPresentationWrapper = styled.div`
  grid-area: camera;

  width: ${(props) =>
    props.isMiniPresentationExpanded
      ? `${props.presentationAreaWidth}px`
      : `${MINI_PRESENTATION_WIDTH}px`};

  height: ${(props) =>
    props.miniPresentationCollapsed
      ? `${PRESENTATION_BUTTON_SIZE}px`
      : props.isMiniPresentationExpanded
      ? `${props.presentationAreaHeight}px`
      : `${MINI_PRESENTATION_HEIGHT}px`};

  transform: ${(props) =>
    props.fullScreenVisible
      ? props.isMiniPresentationExpanded
        ? `none`
        : `translate(-${GRID_GAP}px,${GRID_GAP}px)`
      : props.isMiniPresentationExpanded
      ? `translate(${WINDOW_MARGIN}px,${WINDOW_MARGIN}px)`
      : `translate(-${WINDOW_MARGIN}px,${WINDOW_MARGIN}px)`};

  transition: ${(props) => (props.miniPresentationCollapsed ? "height 0.3s" : "none")};

  .name-user {
    ${(props) =>
      props.isMiniPresentationExpanded
        ? css`
            top: 8px;
            right: 8px;
          `
        : css`
            left: ${(props) => (props.fullScreenVisible ? 8 : 12)}px;
            bottom: 8px;
          `}

    ${(props) =>
      props.miniPresentationCollapsed
        ? css`
            opacity: 0;
            visibility: hidden;

            transition: opacity 0.15s, visibility 0.15s;
          `
        : css`
            opacity: 1;
            visibility: visible;

            transition: opacity 0.6s, visibility 0.6s;
          `};
  }

  .name-user-collapsed {
    top: 8px;
    left: 50%;
    transform: translateX(-50%);

    ${(props) =>
      props.miniPresentationCollapsed
        ? css`
            opacity: 1;
            visibility: visible;

            transition: opacity 0.6s, visibility 0.6s;
          `
        : css`
            opacity: 0;
            visibility: hidden;

            transition: opacity 0.3s, visibility 0.3s;
          `};
  }

  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props.isMiniPresentationExpanded &&
      css`
        display: flex !important;
        position: absolute !important;

        transform: none;
        border-radius: 0 !important;
      `};
  }
`;

const UsernameWrapper = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
