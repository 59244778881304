export const PLAYER_STATUSES = {
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
  WAITING: "WAITING",
  // TODO: ENDED?
};

export const MEDIA_EVENT_STATUS = {
  WAITING: "WAITING",
  CAN_PLAY: "CAN_PLAY",
};

export const PLAYBACK_MEDIA_TYPES = {
  SCREEN_SHARING: "SCREEN_SHARING",
  VIDEO: "VIDEO",
};

export const MEDIA_ELEMENT_NAMES = {
  SCREEN_SHARING: "tutoom-media-screen-sharing",
  VIDEO: "tutoom-media-video",
};

export const DEFAULT_VOLUME = 1;

export const DEFAULT_PLAYER_STATUS = PLAYER_STATUSES.PAUSED;
