import get from "lodash.get";
import { useSelector } from "react-redux";

import { boardsContent } from "lib/boards";

/**
 * @typedef {Object} WhiteboardBackgroundDimensions
 * @property {number} width
 * @property {number} height
 */

/**
 * @returns {WhiteboardBackgroundDimensions | null}
 */
export const useWhiteboardBackgroundDimensions = () => {
  const fileId = useSelector((state) => state.slides.fileId);
  const boardId = useSelector((state) => state.slides.boardId);
  const slideIndex = useSelector((state) => state.slides.slideIndex);

  const board = useSelector((state) => state.boards[boardId]);
  const fileSlide = useSelector((state) => state.slides.slides[`${fileId}:${slideIndex}`]);

  if (!fileId && !boardId) return null;

  // if it's board
  if (board) {
    const boardPage = get(board, `pages[${slideIndex}]`);
    if (!boardPage) return null;

    const content = boardsContent[boardPage.type];
    if (!content) return null;

    return {
      width: content.width,
      height: content.height,
    };
  }

  // if it's file slide
  if (fileSlide) {
    return {
      height: fileSlide.height,
      width: fileSlide.width,
    };
  }

  // if it's file with board page
  return {
    width: boardsContent.BLANK.width,
    height: boardsContent.BLANK.height,
  };
};
