import { boardsContent } from "lib/boards";
import { useSelector } from "react-redux";

import { DEFAULT_TRANSITION_SVG } from "ui";

export const SlideBackground = ({ fileId }) => {
  const slideIndex = useSelector((state) => state.slides.slideIndex);
  const file = useSelector((state) => state.files[fileId]);
  const fileSlide = useSelector((state) => state.slides.slides[`${fileId}:${slideIndex}`]);

  if (!file) return null;

  const slide = file.slides[slideIndex];

  if (!slide) return null;

  if (slide.boardPage) {
    const content = boardsContent[slide.boardPage.type];
    if (!content) return null;

    return (
      <picture>
        <source srcSet={content.webp} type="image/webp" />
        <source srcSet={content.source} type="image/jpg" />
        <img
          src={content.source}
          width="100%"
          alt=""
          style={{ transition: DEFAULT_TRANSITION_SVG }}
          id="imagebox-img"
        />
      </picture>
    );
  }

  if (slide.fileSlide && fileSlide) {
    return (
      <picture>
        <source srcSet={fileSlide.webp} type="image/webp" />
        <source srcSet={fileSlide.source} type="image/jpeg" />
        <img
          src={fileSlide.source}
          width="100%"
          alt=""
          style={{ transition: DEFAULT_TRANSITION_SVG }}
          id="imagebox-img"
        />
      </picture>
    );
  }

  return null;
};
