import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import styled, { css } from "styled-components";

import { eventTimer } from "services/timer";

import { formatTime } from "lib/time";

import { useThumbnailSelected } from "hooks/useThumbnailSelected";

import { slidesActions } from "store/slides";

import { BREAKPOINTS, SLIDE_LG_HEIGHT } from "theme/ui";
import { colors } from "theme";

export const Slide = forwardRef(({ index, thumbnail }, slideRef) => {
  const dispatch = useDispatch();
  const { isThumbnailSelected } = useThumbnailSelected({ thumbnail });

  const onChangeSlide = (position, time) => {
    eventTimer.requestSeek(time);

    dispatch(slidesActions.slideIndexChanged(position));
    dispatch(slidesActions.slidesVisibleClosed());
  };

  return (
    <SlideWrapper
      className={classNames("relative overflow-hidden shadow-all cursor-pointer p-2")}
      key={index}
      ref={slideRef}
      selected={isThumbnailSelected}
      onClick={() => onChangeSlide(thumbnail.index, thumbnail.time)}
    >
      <div className="w-full h-full overflow-hidden relative rounded-xxl select-none">
        <span className="flex justify-center items-center absolute top-0 left-0 bg-black text-white text-12 rounded-xxl ml-4 mt-4 py-2 px-4 select-none">
          {formatTime(thumbnail.time)}
        </span>
        <img src={thumbnail.source} alt="" className="slide-image w-full h-full" />
      </div>
    </SlideWrapper>
  );
});

const SlideWrapper = styled.div`
  height: ${SLIDE_LG_HEIGHT}px;
  background: transparent;
  border-radius: 16px;

  ${(props) =>
    props.selected
      ? css`
          background: transparent;
          border: 4px solid ${colors.turquoise};

          &:hover {
            background: transparent;
          }
        `
      : css`
          border: 4px solid transparent;

          &:hover {
            background: rgba(255, 255, 255, 0.32);
          }
        `}

  transition: background 0.35s;

  .slide-image {
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 100%;
  }
`;
